import React from 'react'
import {StyleSheet, TextProps} from 'react-native'
import Svg, {PathProps, SvgProps, Defs, G, Path, Stop, LinearGradient} from 'react-native-svg'
import {Image} from 'expo-image'

import {colors} from '#/lib/styles'
import {useKawaiiMode} from '#/state/preferences/kawaii'

const ratio = 57 / 64

type Props = {
  fill?: PathProps['fill']
  style?: TextProps['style']
} & Omit<SvgProps, 'style'>

export const Logo = React.forwardRef(function LogoImpl(props: Props, ref) {
  const {fill, ...rest} = props
  const gradient = fill === 'sky'
  const styles = StyleSheet.flatten(props.style)
  const _fill = gradient ? 'url(#sky)' : fill || styles?.color || colors.blue3
  // @ts-ignore it's fiiiiine
  const size = parseInt(rest.width || 32)

  const isKawaii = useKawaiiMode()

  if (isKawaii) {
    return (
      <Image
        source={
          size > 100
            ? require('../../../assets/kawaii.png')
            : require('../../../assets/kawaii_smol.png')
        }
        accessibilityLabel="Opensky"
        accessibilityHint=""
        accessibilityIgnoresInvertColors
        style={[{height: size, aspectRatio: 1.4}]}
      />
    )
  }

  return (
    <Svg
      fill="none"
      // @ts-ignore it's fiiiiine
      ref={ref}
      viewBox="0 0 128 128"
      {...rest}
      style={[{width: size, height: size * ratio}, styles]}>
  <Defs id="defs1">
    <LinearGradient id="linearGradient19">
      <Stop stopColor="#0471d0" stopOpacity="1" offset="0" id="stop19" />
      <Stop stopColor="#27e8e8" stopOpacity="1" offset="1" id="stop14" />
    </LinearGradient>
    <LinearGradient xlinkHref="#linearGradient19" id="linearGradient9" x1="307.5542" y1="461.87662" x2="433.27914" y2="461.87662" gradientUnits="userSpaceOnUse" />
  </Defs>
  <G id="layer1" transform="translate(-307.5542,-427.93841)">
    <Path fontVariationSettings="normal" fill={_fill} fillOpacity="1" stroke="#ededed" strokeWidth="4.7625" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="4" strokeDasharray="none" strokeDashoffset="0" strokeOpacity="1" stopColor="#000000" d="m 321.55256,492.75369 c -12.339,-3.85818 -15.69712,-20.57257 -6.06788,-30.20179 2.95016,-2.95015 7.39437,-5.16533 12.10583,-6.03401 l 3.36325,-0.6201 0.32972,-2.96048 c 0.64355,-5.7782 4.06615,-10.53425 9.49643,-13.19625 3.86691,-1.8956 9.37011,-2.00154 13.31732,-0.25635 l 2.55241,1.12853 2.76955,-2.99149 c 4.66249,-5.036 12.04937,-7.91038 18.46837,-7.18635 3.68297,0.4154 7.6975,1.82401 10.29618,3.61268 2.74156,1.88703 6.52719,5.9636 7.57137,8.15324 0.82338,1.72672 0.85767,1.7418 4.04892,1.77948 7.67247,0.0906 13.82869,4.68966 15.9825,11.93988 0.39502,1.32974 0.71824,3.11475 0.71824,3.96666 0,1.47304 0.12903,1.57779 2.63377,2.13833 7.27739,1.62862 11.7983,7.81405 11.75911,16.08871 -0.0322,6.80897 -4.12565,12.4275 -10.49941,14.41124 -2.86546,0.89183 -5.1082,0.93234 -49.89938,0.90101 -37.14065,-0.0259 -47.32504,-0.166 -48.9463,-0.67294 z" id="logo" />
  </G>
</Svg>
  )
})
