import Svg, {PathProps, SvgProps, Defs, G, Path, Stop, LinearGradient} from 'react-native-svg'

import {usePalette} from '#/lib/hooks/usePalette'

const ratio = 17 / 64

export function Logotype({
  fill,
  ...rest
}: {fill?: PathProps['fill']} & SvgProps) {
  const pal = usePalette('default')
  // @ts-ignore it's fiiiiine
  const size = parseInt(rest.width || 32)

  return (
    <Svg
      fill="none"
      viewBox="0 0 210 57"
      {...rest}
      width={size}
      height={Number(size) * ratio}>
  <Defs id="defs1">
    <LinearGradient id="linearGradient19">
      <Stop stopColor="#0471d0" stopOpacity="1" offset="0" id="stop19" />
      <Stop stopColor="#27e8e8" stopOpacity="1" offset="1" id="stop14" />
    </LinearGradient>
    <LinearGradient xlinkHref="#linearGradient19" id="linearGradient2" gradientUnits="userSpaceOnUse" x1="86.050644" y1="569.79578" x2="939.68329" y2="569.79578" />
    <LinearGradient xlinkHref="#linearGradient19" id="linearGradient222" gradientUnits="userSpaceOnUse" x1="86.050644" y1="569.79578" x2="939.68329" y2="569.79578" />
    <LinearGradient xlinkHref="#linearGradient19" id="linearGradient224" gradientUnits="userSpaceOnUse" x1="86.050644" y1="569.79578" x2="939.68329" y2="569.79578" />
    <LinearGradient xlinkHref="#linearGradient19" id="linearGradient226" gradientUnits="userSpaceOnUse" x1="86.050644" y1="569.79578" x2="939.68329" y2="569.79578" />
    <LinearGradient xlinkHref="#linearGradient19" id="linearGradient228" gradientUnits="userSpaceOnUse" x1="86.050644" y1="569.79578" x2="939.68329" y2="569.79578" />
    <LinearGradient xlinkHref="#linearGradient19" id="linearGradient230" gradientUnits="userSpaceOnUse" x1="86.050644" y1="569.79578" x2="939.68329" y2="569.79578" />
    <LinearGradient xlinkHref="#linearGradient19" id="linearGradient232" gradientUnits="userSpaceOnUse" x1="86.050644" y1="569.79578" x2="939.68329" y2="569.79578" />
    <LinearGradient xlinkHref="#linearGradient19" id="linearGradient234" gradientUnits="userSpaceOnUse" x1="86.050644" y1="569.79578" x2="939.68329" y2="569.79578" />
  </Defs>
  <G id="layer1" transform="translate(-303.46821,-4.8072227)">
    <G id="Logotype" transform="matrix(0.26458333,0,0,0.26458333,289.05249,-262.78778)" fill={fill || pal.text.color} fillOpacity="1" stroke="#ffffff" strokeWidth="3" strokeDasharray="none" strokeOpacity="1">
      <G aria-label="opensky" id="text1" fontSize="204.978px" lineHeight="1.25" fontFamily="Merriweather" letterSpacing="-10px" display="inline" fill="url(#linearGradient2)">
        <Path d="m 157.6537,1113.5269 q 0,27.2621 -13.9385,42.2255 -13.73353,14.9634 -37.306,14.9634 -14.553434,0 -26.032202,-6.5593 -11.27379,-6.7643 -17.833085,-19.4729 -6.559296,-12.9136 -6.559296,-31.1567 0,-27.262 13.733525,-42.0205 13.733526,-14.7584 37.305998,-14.7584 14.96339,0 26.23718,6.7643 11.47877,6.5593 17.83309,19.2679 6.55929,12.5037 6.55929,30.7467 z m -83.016086,0 q 0,19.4729 7.584186,30.9517 7.789164,11.2738 24.59736,11.2738 16.60322,0 24.39238,-11.2738 7.78917,-11.4788 7.78917,-30.9517 0,-19.4729 -7.78917,-30.5417 -7.78916,-11.0688 -24.59736,-11.0688 -16.808194,0 -24.39238,11.0688 -7.584186,11.0688 -7.584186,30.5417 z" id="path90" fill="url(#linearGradient222)" />
        <Path d="m 228.41494,1056.748 q 20.29282,0 32.5915,14.1435 12.50366,14.1435 12.50366,42.6354 0,28.082 -12.50366,42.6355 -12.29868,14.5534 -32.79648,14.5534 -12.70863,0 -21.11273,-4.7145 -8.19912,-4.9195 -12.91361,-11.2738 h -1.22987 q 0.40996,3.4846 0.81991,8.8141 0.40996,5.3294 0.40996,9.224 v 45.0951 h -18.03807 v -159.0629 h 14.75842 l 2.45974,14.9634 h 0.81991 q 4.91947,-7.1742 12.91361,-12.0937 7.99414,-4.9195 21.31771,-4.9195 z m -3.27965,15.1684 q -16.80819,0 -23.77744,9.429 -6.76428,9.429 -7.17423,28.6969 v 3.4846 q 0,20.2929 6.55929,31.3617 6.76428,10.8638 24.80234,10.8638 10.04392,0 16.39824,-5.5344 6.5593,-5.5344 9.63397,-14.9634 3.27964,-9.634 3.27964,-21.9326 0,-18.858 -7.3792,-30.1318 -7.17423,-11.2738 -22.34261,-11.2738 z" id="path92" fill="url(#linearGradient224)" />
        <Path d="m 334.63743,1056.748 q 14.14348,0 24.1874,6.1494 10.2489,6.1493 15.57833,17.4231 5.53441,11.0688 5.53441,26.0322 v 10.8638 h -75.22693 q 0.40996,18.653 9.42899,28.492 9.22401,9.6339 25.62225,9.6339 10.45388,0 18.44802,-1.8448 8.19912,-2.0497 16.8082,-5.7393 v 15.7833 q -8.4041,3.6896 -16.60322,5.3294 -8.19912,1.8448 -19.47291,1.8448 -15.57833,0 -27.67203,-6.3543 -11.88872,-6.3543 -18.653,-18.858 -6.55929,-12.7086 -6.55929,-30.9517 0,-18.038 5.94436,-30.9517 6.14934,-12.9136 17.01317,-19.8828 11.06881,-6.9693 25.62225,-6.9693 z m -0.20498,14.7584 q -12.91361,0 -20.4978,8.4041 -7.37921,8.1992 -8.81405,22.9576 h 55.95899 q -0.20498,-13.9385 -6.55929,-22.5476 -6.35432,-8.8141 -20.08785,-8.8141 z" id="path94" fill="url(#linearGradient226)" />
        <Path d="m 450.69873,1056.748 q 19.67789,0 29.72181,9.634 10.04392,9.429 10.04392,30.7467 v 71.5373 h -17.83308 v -70.3074 q 0,-26.4422 -24.59736,-26.4422 -18.24305,0 -25.2123,10.2489 -6.96925,10.2489 -6.96925,29.5168 v 56.9839 h -18.03806 v -109.8682 h 14.55343 l 2.66472,14.9634 h 1.02489 q 5.32943,-8.6091 14.75841,-12.7086 9.42899,-4.3046 19.88287,-4.3046 z" id="path96" fill="url(#linearGradient228)" />
        <Path d="m 586.02812,1138.3293 q 0,15.9883 -11.88872,24.1874 -11.88873,8.1991 -31.97657,8.1991 -11.47877,0 -19.88287,-1.8448 -8.19912,-1.8448 -14.55344,-5.1245 v -16.3982 q 6.5593,3.2797 15.78331,6.1493 9.42899,2.6648 19.06295,2.6648 13.73353,0 19.88287,-4.3046 6.14934,-4.5095 6.14934,-11.8887 0,-4.0996 -2.25476,-7.3792 -2.25476,-3.2797 -8.19912,-6.5593 -5.73938,-3.2797 -16.60322,-7.3792 -10.65885,-4.0996 -18.24304,-8.1991 -7.58418,-4.0996 -11.68374,-9.839 -4.09956,-5.7394 -4.09956,-14.7584 0,-13.9385 11.27379,-21.5227 11.47876,-7.5842 29.92678,-7.5842 10.04393,0 18.653,2.0498 8.81406,1.8448 16.39824,5.3294 l -6.14934,14.3485 q -6.96925,-2.8697 -14.55344,-4.9195 -7.58418,-2.0498 -15.57832,-2.0498 -11.06882,0 -17.01318,3.6896 -5.73938,3.4847 -5.73938,9.634 0,4.5095 2.66471,7.7892 2.66472,3.0746 8.81406,6.1493 6.35431,2.8697 16.80819,6.9693 10.45388,3.8946 17.83309,7.9941 7.37921,4.0996 11.27379,10.0439 3.89458,5.7394 3.89458,14.5535 z" fill="url(#linearGradient230)" id="path98" />
        <Path d="m 620.50836,1094.259 q 0,3.2797 -0.40995,8.6091 -0.20498,5.3294 -0.40996,9.224 h 0.81991 q 1.22987,-1.6398 3.68961,-4.7145 2.45973,-3.0747 4.91947,-6.1493 2.66471,-3.2797 4.50951,-5.3295 l 35.05124,-37.101 h 21.11274 l -44.48023,46.94 47.5549,62.9282 h -21.72767 l -38.12591,-51.2445 -12.50366,10.8639 v 40.3806 h -17.83308 v -155.7833 h 17.83308 z" fill="url(#linearGradient232)" id="path100" />
        <Path d="m 684.91544,1058.7978 h 19.26793 l 23.77745,62.5183 q 3.07467,8.1991 5.5344,15.7833 2.45974,7.3792 3.68961,14.1435 h 0.81991 q 1.22987,-5.1245 3.89458,-13.3236 2.66472,-8.4041 5.53441,-16.8082 l 22.3426,-62.3133 h 19.47291 l -47.34992,125.0366 q -5.73938,15.5783 -14.96339,24.8023 -9.01903,9.224 -25.00732,9.224 -4.91947,0 -8.60907,-0.6149 -3.68961,-0.41 -6.35432,-1.0249 v -14.3484 q 2.25476,0.4099 5.32943,0.8199 3.27964,0.4099 6.76427,0.4099 9.42899,0 15.16837,-5.3294 5.94436,-5.3294 9.22401,-14.1435 l 5.73939,-14.5534 z" fill="url(#linearGradient234)" id="path102" />
      </G>
    </G>
  </G>
</Svg>
  )
}
